<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <ASuccessFour :api="api"/>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">

        </v-skeleton-loader>
        <v-row class="mt-3" v-else>
            <v-col align="center" justify="center" cols="12" sm="8" class="mx-auto">
                <v-col align="start" justify="start">
                    <v-btn
                    @click="redirectBack"
                    class="mb-3"
                    color="primary">
                        <v-icon>
                            mdi-arrow-left-bold
                        </v-icon>
                    </v-btn>
                </v-col>
                <v-card class="pa-3" elevation-1>
                    <!--BOC : Create a form here-->
                    <v-toolbar class="secondary white--text text-h4 mb-4">
                        <v-toolbar-title>
                            Survey
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-row 
                        v-if="data!=null">
                        <v-col>
                            <div 
                                class="d-flex justify-start mt-3">
                                <v-text-field
                                    label="type"
                                    dense
                                    outlined
                                    disabled
                                    v-model="data.type">
                                </v-text-field>
                            </div>
                            <div 
                                class="d-flex justify-start ">
                                <v-select
                                :items="surveyEnableOptions"
                                label="Survey Status"
                                v-model="data.status"
                                value="value"
                                name="text"
                                return-object
                                dense 
                                outlined>
                                </v-select>
                            </div>
                            <div
                                class="d-flex justify-start">
                                <v-select
                                    :items="surveyEnableOptions"
                                    v-model="data.testing"
                                    label="Testing"
                                    dense
                                    return-object
                                    outlined>

                                </v-select>
                            </div>
                            <div
                                class="d-flex justify-start">
                                <v-text-field
                                    dense 
                                    outlined 
                                    disabled 
                                    v-model="data.tester"
                                    label="Tester Id">
                                </v-text-field>
                            </div>
                      
                            <div
                                class="d-flex justify-end">
                                <v-btn
                                    color="primary"
                                    plain>
                                    Cancel
                                </v-btn>
                                <v-btn
                                    @click="validateInput"
                                    color="primary">
                                    Confirm
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                    <!--EOC-->
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import AConfirmation from '../../components/common/AConfirmation.vue';
import ASuccessFour from '../../components/common/ASuccessFour.vue';
export default {
    components:{
    AConfirmation,
    ASuccessFour,
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        data:null,
        surveyEnableOptions:[
            {
                text:'Enabled',
                value:"1",
            },
            {
                text:'Disabled',
                value:"0",
            },
        ],
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class=="getSurveySetting"){
                this.data = resp.data;
            }
            if(resp.class==="updateSurveySettings"){
                this.api.isSuccesful = true;
                this.api.success = resp.message
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            let fetchSurveySettingApi = this.fetchSurveySetting();
            this.$api.fetch(fetchSurveySettingApi);
        },
        fetchSurveySetting(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method ="GET";
            let url = process.env.VUE_APP_SERVER_API.replace('v1','v4');
            tempApi.url = url+"/survey/settings/"+this.$router.history.current.params.id;
            return tempApi;
        },
        validateInput(){
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;
            let updateApi = this.update();
            this.$api.fetch(updateApi);
        },
        update(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method ="PUT";
            let url = process.env.VUE_APP_SERVER_API.replace("v1","v4");
            tempApi.url = url+"/survey/settings/"+this.$router.history.current.params.id;
            tempApi.params = {
                "status":(this.data.status.value)?this.data.status.value:this.data.status,
                "tester":this.data.tester,
                "testing":(this.data.testing.value)?this.data.testing.value:this.data.testing,
            };
            return tempApi;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        create(){

        },
    }
}
</script>